<template>
  <fair-layout v-if="fair" :fair-id="fair.id">
    <template v-slot:banner>
      <banner
        v-if="fair"
        :title="fair.name"
        type="banner--admin"
        :options="{padding: '30px'}">
      </banner>
    </template>
    <template v-slot:content>
      <div class="px-7">
        <div class="row">
          <div class="col-12 d-flex align-center justify-space-between">
            <h2>Configuración Recursos Planos Feria</h2>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <data-table ref="placeSettings" :url="url" :params="params" :headers="headers" :withoutPagination="true">
               <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      outlined
                      class="ma-2 elevation-0"
                      v-bind="attrs"
                      v-on="on"
                      @click="openPlaceSettingModal(item)"
                      small
                      color="primary"
                    >
                      <v-icon
                        center
                        dark
                        small
                      >
                        fa fa-edit
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Editar Pabellón</span>
                </v-tooltip>
              </template>
            </data-table>
          </div>
        </div>
        <place-setting-modal ref="placeSettingModal" :fairId="fair.id" @onClose="onClose"/>
      </div>
    </template>
  </fair-layout>
</template>
<script>
import FairLayout from '@/layouts/FairLayout'
import DataTable from '@/components/data-table/DataTable'
import crudServiceMixin from '@/mixins/crudServiceMixin'
import { FAIRS_URL, PLACE_SETTING_URL } from '@/constants/ServicesConstants'
import headers from '@/views/private/places-settings/data/Headers'
import sessionMixin from '@/mixins/sessionMixin'
import formOperationsMixin from '@/mixins/formOperationsMixin'
import loaderMixin from '@/mixins/loaderMixin'
import notificationMixin from '@/mixins/notificationMixin'
import PlaceSettingModal from '@/views/private/places-settings/components/PlaceSettingModal'
import Banner from '@/components/banner/Banner'

export default {
  beforeRouteEnter (to, from, next) {
    const user = sessionMixin.methods.getUser()
    if (sessionMixin.methods.isSuperAdmin(user.roleId)) {
      next()
    } else {
      next({ path: '/error-403' })
    }
  },
  components: {
    FairLayout,
    Banner,
    DataTable,
    PlaceSettingModal
  },
  data () {
    return {
      headers,
      url: PLACE_SETTING_URL,
      params: {
        fairId: this.$route.params.fairId
      },
      fair: null
    }
  },
  methods: {
    isActive (isActive) {
      return Boolean(isActive)
    },
    onClose () {
      this.$refs.placeSettings.getDataFromApi()
    },
    openPlaceSettingModal (item) {
      this.$refs.placeSettingModal.open(item)
    },
    async getFair () {
      const response = await this.get(FAIRS_URL + '/' + this.$route.params.fairId)
      this.fair = response.data
    }
  },
  async created () {
    try {
      this.showLoader()
      await this.getFair()
      this.hideLoader()
    } catch {
      this.hideLoader()
      this.showError()
    }
  },
  mixins: [
    crudServiceMixin,
    formOperationsMixin,
    loaderMixin,
    notificationMixin
  ],
  name: 'PlacesSettings'
}
</script>
