const headers = [
  {
    text: 'Nombre Pabellón',
    value: 'name',
    sortable: false
  },
  {
    text: 'Color Botón Pabellón',
    value: 'appBtnColor',
    sortable: false
  },
  {
    text: 'Color Texto Botón',
    value: 'appBtnTextColor',
    sortable: false
  },
  {
    text: '',
    value: 'actions',
    sortable: false,
    width: '15%'
  }
]
export default headers
