<template>
  <v-row justify="center">
    <v-dialog v-model="isActive" max-width="800px" persistent scrollable>
      <v-card>
        <v-card-title>
          <span class="headline">
            Colores del Pabellón
          </span>
        </v-card-title>
        <v-card-text class="mt-10">
          <validation-observer ref="form">
            <v-container >
              <div class="row">
                <div class="col-12 col-md-6">
                  <div class="row">
                    <div class="col-12">
                      <label class="mb-3">Color botón del Pabellón</label><br>
                    </div>
                    <div class="col-12">
                      <validation-provider
                        v-slot="{ errors }"
                        name="Color botón del Pabellón"
                        vid="appBtnColor"
                        rules="required"
                      >
                        <v-color-picker
                          v-model="model.appBtnColor"
                          dot-size="15"
                          hide-mode-switch
                          mode="hexa"
                          :error-messages="errors"
                        ></v-color-picker>
                      </validation-provider>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="row">
                    <div class="col-12">
                      <label class="mb-3">Color texto del botón</label><br>
                    </div>
                    <div class="col-12">
                      <validation-provider
                        v-slot="{ errors }"
                        name="Color texto del boton"
                        vid="appBtnTextColor"
                        rules="required"
                      >
                        <v-color-picker
                          v-model="model.appBtnTextColor"
                          dot-size="15"
                          hide-mode-switch
                          mode="hexa"
                          :error-messages="errors"
                        ></v-color-picker>
                      </validation-provider>
                    </div>
                  </div>
                </div>
              </div>
            </v-container>
          </validation-observer>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="updatePlaceColors">
            Aceptar
          </v-btn>
          <v-btn color="red" text @click="close">
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import crudServiceMixin from '@/mixins/crudServiceMixin'
import loaderMixin from '@/mixins/loaderMixin'
import notificationMixin from '@/mixins/notificationMixin'
import { PLACE_SETTING_URL } from '@/constants/ServicesConstants'
import formOperationsMixin from '@/mixins/formOperationsMixin'

export default {
  name: 'PlaceSettingModal',
  props: {
    fairId: {
      type: Number
    }
  },
  data () {
    return {
      isActive: false,
      model: {
        id: null,
        appBtnColor: null,
        appBtnTextColor: null
      }
    }
  },
  methods: {
    close () {
      this.isActive = false
      this.clean()
    },
    clean () {
      this.$refs.form.reset()
      this.model = {
        id: null,
        appBtnColor: null,
        appBtnTextColor: null
      }
    },
    open (item) {
      this.model.id = item.id
      this.model.appBtnColor = item.appBtnColor
      this.model.appBtnTextColor = item.appBtnTextColor
      this.isActive = true
    },
    showColor (color) {
      if (typeof color === 'string') {
        return color
      }

      return JSON.stringify(Object.keys(color).reduce((color, key) => {
        color[key] = Number(color[key].toFixed(2))
        return color
      }, {}), null, 2)
    },
    updatePlaceColors () {
      if (typeof this.model.appBtnColor === 'object') {
        this.model.appBtnColor = this.model.appBtnColor.hex
      }
      if (typeof this.model.appBtnTextColor === 'object') {
        this.model.appBtnTextColor = this.model.appBtnTextColor.hex
      }
      this.executeWithFormValidation(() => {
        this.model.fairId = this.fairId
        const formData = this.getFormData()
        this.create(PLACE_SETTING_URL, formData).then(() => {
          this.close()
          this.$emit('onClose')
        })
      })
    }
  },
  mixins: [
    crudServiceMixin,
    formOperationsMixin,
    notificationMixin,
    loaderMixin
  ]
}
</script>
